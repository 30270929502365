import React from "react";
import useCommonContext from "../../store/CommonContext";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Grid, Tooltip, Link } from "@mui/material";
import RefreshComponent from "../RefreshComponent";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../utils/FormattingUtils";
import { currencySetter } from "../../utils/Currencyutil";
import { datemonthYear } from "../../utils/DateUtils";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Spinner } from "@nextui-org/react";

const RecentHistory = () => {
  const { getRecentData, recentData, recentLoading } = useCommonContext();
  const navigate = useNavigate();

  return (
    <Box
      className="card-css"
      sx={{
        mt: 2,
        px: 1,
        py: 1.5,
        borderRadius: "10px",
        background: "linear-gradient(135deg, #f0f9ff, #e0f7fa)",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
          fontSize: "20px",
          color: "#2A4D69", // A deep slate-blue for a modern look
          letterSpacing: "0.3px",
          textTransform: "uppercase",
        }}
      >
        Recent Transactions
        <RefreshComponent
          progressColor="#004080"
          color="#008ecc"
          refresh={recentLoading}
          onClick={getRecentData}
          size="1rem"
        />
        <Spinner loading={recentLoading} circleBlue />
      </Box>

      <Box
        sx={{
          mt: 1,
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {recentData.map((data, index) => {
          const walletBal = Number(data.amount).toFixed(2);
          const statusColor =
            {
              SUCCESS: "#dff0d8",
              FAILED: "#f2dede",
              REFUND: "#fcf8e3",
              PENDING: "#fbeed5",
            }[data.status] || "#f5f5f5";

          const iconColor = {
            SUCCESS: "#259625",
            FAILED: "#ff2c2c",
            REFUND: "#E87204",
            PENDING: "#f48f26",
          }[data.status];

          return (
            <Grid
              container
              key={index}
              sx={{
                borderRadius: 3,
                px: 1,
                py: 1,
                mb: 1,
                backgroundColor: statusColor,
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={1.5}
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: iconColor,
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)", // Optional shadow for visual depth
                }}
              >
                {/* Display different icons based on transaction status */}
                {data.status === "SUCCESS" && (
                  <ArrowUpwardIcon sx={{ color: "#fff", fontSize: "20px" }} />
                )}
                {data.status === "FAILED" && (
                  <ArrowDownwardIcon sx={{ color: "#fff", fontSize: "20px" }} />
                )}
                {data.status === "REFUND" && (
                  <SyncIcon sx={{ color: "#fff", fontSize: "20px" }} />
                )}
                {data.status === "PENDING" && (
                  <PriorityHighIcon sx={{ color: "#fff", fontSize: "20px" }} />
                )}
              </Grid>

              <Grid
                item
                xs={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  color: "#004080",
                  ml: 0.7,
                  gap: "3px",
                }}
              >
                <Tooltip
                  title={
                    data.operator === "Vendor Payments"
                      ? "Settlements"
                      : data.operator
                  }
                >
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "130px",
                      textAlign: "left",
                    }}
                  >
                    {data.operator === "Vendor Payments"
                      ? "Settlements"
                      : data.operator}
                  </div>
                </Tooltip>
                <div
                  style={{
                    fontSize: "12px",
                    color: "#787879",
                    textAlign: "left",
                  }}
                >
                  {data.number}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "#787879",
                    textAlign: "left",
                  }}
                >
                  {datemonthYear(data.created_at)}
                </div>
              </Grid>

              <Grid item xs={3} sx={{ textAlign: "right" }}>
                <Box
                  sx={{
                    color:
                      data.status === "SUCCESS"
                        ? "#4caf50"
                        : data.status === "PENDING"
                        ? "#f48f26"
                        : data.status === "REFUND"
                        ? "#ff9800"
                        : "#e53935",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  <span>{data.txn_type === "DR" ? "- " : "+ "}</span>
                  {currencySetter(walletBal)}
                </Box>
                <Box
                  sx={{
                    color:
                      data.status === "SUCCESS"
                        ? "#4caf50"
                        : data.status === "PENDING"
                        ? "#f48f26"
                        : data.status === "REFUND"
                        ? "#ff9800"
                        : "#e53935",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  {capitalize(data.status)}
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Box>
      <Box
        sx={{
          textAlign: "right",
          mt: 1,
        }}
      >
        <Link
          to="/customer/transactions"
          onClick={() => navigate("/customer/transactions")}
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#008ecc",
            backgroundColor: "#f0f9ff",
            padding: "0.5rem 1rem",
            borderRadius: "20px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          More
        </Link>
      </Box>
    </Box>
  );
};

export default RecentHistory;
